import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
// import Wrapper from './components/Wrapper';
import PostWrapper from './components/PostWrapper';
import Home from './pages/Home';
import Skincare, { GlymedPlusSeries } from './pages/Skincare';
import Lifestyle from './pages/Lifestyle';
import { SpanishSeries, MarriageSeries } from './pages/Lifestyle';
import SpanishSeriesPostOne from './posts/Lifestyle/SpanishSeries/post-one.mdx';
import MarriageSeriesPostOne from './posts/Lifestyle/MarriageSeries/post-one.mdx';
import MarriageSeriesPostTwo from './posts/Lifestyle/MarriageSeries/post-two.mdx';
import MarriageSeriesPostThree from './posts/Lifestyle/MarriageSeries/post-three.mdx';
import MarriageSeriesPostFour from './posts/Lifestyle/MarriageSeries/post-four.mdx';
import MarriageSeriesPostFive from './posts/Lifestyle/MarriageSeries/post-five.mdx';
import MarriageSeriesPostSix from './posts/Lifestyle/MarriageSeries/post-six.mdx';

export default function Routes() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/"><Home /></Route>
				<Route exact path="/skincare"><Skincare /></Route>

				<Route exact path="/skincare/glymed-plus-series"><GlymedPlusSeries /></Route>
				<Route exact path="/lifestyle/spanish-series/post-one">
					<PostWrapper postId="spanish-series-post-one"><SpanishSeriesPostOne /></PostWrapper>
				</Route>

				<Route exact path="/lifestyle"><Lifestyle /></Route>

				<Route exact path="/lifestyle/spanish-series"><SpanishSeries /></Route>
				<Route exact path="/lifestyle/spanish-series/post-one">
					<PostWrapper postId="spanish-series-post-one"><SpanishSeriesPostOne /></PostWrapper>
				</Route>

				<Route exact path="/lifestyle/marriage-series"><MarriageSeries /></Route>
				<Route exact path="/lifestyle/marriage-series/post-one">
					<PostWrapper postId="marriage-series-post-one"><MarriageSeriesPostOne /></PostWrapper>
				</Route>
				<Route exact path="/lifestyle/marriage-series/post-two">
					<PostWrapper postId="marriage-series-post-two"><MarriageSeriesPostTwo /></PostWrapper>
				</Route>
				<Route exact path="/lifestyle/marriage-series/post-three">
					<PostWrapper postId="marriage-series-post-three"><MarriageSeriesPostThree /></PostWrapper>
				</Route>
				<Route exact path="/lifestyle/marriage-series/post-four">
					<PostWrapper postId="marriage-series-post-four"><MarriageSeriesPostFour /></PostWrapper>
				</Route>
				<Route exact path="/lifestyle/marriage-series/post-five">
					<PostWrapper postId="marriage-series-post-five"><MarriageSeriesPostFive /></PostWrapper>
				</Route>
				<Route exact path="/lifestyle/marriage-series/post-six">
					<PostWrapper postId="marriage-series-post-six"><MarriageSeriesPostSix /></PostWrapper>
				</Route>
			</Switch>
		</BrowserRouter>
	);
}
