import React from 'react';
import Wrapper from '../../../components/Wrapper';
import SubLink from '../../../components/SubLink';
import styles from './styles.module.css';

const SpanishSeries = () => {
    return (
        <Wrapper>
            <div className={styles.lifestyle}></div>
            <SubLink className={styles.sublinks} to="/lifestyle/spanish-series/post-one">SPANISH LEARNING INTRO</SubLink>
        </Wrapper>
    );
};

export default SpanishSeries;