import { createPhoto } from './createPhoto';

const photos = [
  createPhoto('wedding_pics/wedding-pic36.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic38.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic39.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic40.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic41.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic45.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic48.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic49.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic55.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic57.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic52.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic56.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic58.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic59.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic66.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic64.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic67.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic69.jpg', 3, 2),
];

export default photos;
