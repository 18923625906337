import React from 'react';
import styles from './styles.module.css';

const PhotoCred = () => {
  return (
    <a className={styles.photos} href="https://laurelelizabethphotos.com" target="_blank" rel="noopener noreferrer">
      <span>Photos by:</span>
      <div>Laurel Elizabeth</div>
    </a>
  );
};

export default PhotoCred;
