import React from 'react';
import cls from 'classnames';
import skincare_button from '../../images/skincare_icon4.png';
import styles from './styles.module.css';

const SkincareButton = (props) => {
	return (
		<div className={cls(styles.skincare, props.className)}>
			<a href="https://glymedplus.com/store/shapesesthetics" target="_blank" rel="noopener noreferrer">
                <img src={skincare_button} alt="instagram" />
            </a>
		</div>
	);
};

export default SkincareButton;