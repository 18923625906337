import React from 'react';
import Wrapper from '../../components/Wrapper';
import SubLink from '../../components/SubLink';
import styles from './styles.module.css';

const Skincare = () => {
    return (
        <Wrapper>
            <div className={styles.skincare}>SKINCARE</div>
            <SubLink to="skincare/glymed-plus-series">THE <span className={styles.glymedplus}>GLYMED PLUS</span> SERIES</SubLink>
            <SubLink to="lifestyle/esthetician series">THE ESTHETICIAN SERIES</SubLink>
        </Wrapper>
    );
};

export default Skincare;
export { default as GlymedPlusSeries } from './GlymedPlusSeries';