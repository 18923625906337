
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"7 min read","minutes":6.035,"time":362100,"words":1207}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "wedding-plans"
    }}>{`WEDDING PLANS`}</h1>
    <p>{`Planning a wedding always seems fun when you’re on Pinterest and not actually spending the money on these things that (you think) you want. It’s a lot harder than just saving an image to a category online. Once you see those dollar signs go up and up and up you really start to think about what you actually need VS want. `}</p>
    <p>{`First you have to decide what kind of wedding you want: Country, elegant, rustic, beach, vintage, even Disney themed weddings are a thing! Then a venue: You can book out a resort or hotel with a ballroom/ conference room. Then everyone has a place to stay. You could book out a park area. I know some people who have booked out union halls as well. The list of possibilities ( and prices ) goes on! Maybe that’s why it’s so hard to narrow down what kind of wedding you BOTH want?`}</p>
    <p>{`The most important aspect of our wedding, other than being married, was that our immediate families get to spend quality time together. My family lives in Kentucky and Sean’s family lives in Utah ( and as of now we live in Arizona, so we don’t get to spend a lot of time with either side! ) In the span of our five year relationship our families have only gotten together two or three times, not a lot. We knew that having a traditional wedding would take away time spent with our parents and siblings. `}</p>
    <p>{`We are not the type to juggle a lot of venders and DIY stuff, we know that about ourselves! Also, outsourcing tasks to our families, on different time zones, just felt like a hassle ( to us - not to our families ) It just wasn’t in our will power to have so many moving parts going all at once. `}</p>
    <p>{`So, here is what we did:`}</p>
    <ol>
      <li parentName="ol">{`We to a Clerk of Court near by to purchase and pick up a marriage license. In Arizona, the marriage license is good for one year not signed and must be turned back in thirty days after signing. `}</li>
      <li parentName="ol">{`Purchased rings. We only purchased Sean’s ring and my engagement ring. We are waiting to celebrate the wedding band until we have our families together to witness.`}</li>
      <li parentName="ol">{`Purchased gown, suit and all accessories.`}</li>
      <li parentName="ol">{`Found a photographer: When looking for a photographer find someone who edits the way you like, look at their portfolio. Spend. The. Money. On photography. Ask your friends who they used. Search for professional photography at the location you want your photos taken at. Read the contract, front and back, twice! We found our photographer through word of mouth.`}</li>
      <li parentName="ol">{`Made our own bouquet and boutonniere and stored them up right in the fridge two days before our photoshoot. I was quoted $150 for just my bouquet and that was a big no for me! We also got champagne and flutes to celebrate at the photoshoot. In addition, I did get my nails done, but I did my own makeup, hair and used tanning lotion.`}</li>
      <li parentName="ol">{`Took our photos and a local state park around the Phoenix area.`}</li>
      <li parentName="ol">{`Got married at the courthouse. Just us. Court house rules are different everywhere you go so make sure to CALL and talk to someone. Some court houses let you bring X amount of people and everything is provided, some do not. You need witnesses if you are going to get married at the courthouse, some are provided for you, some courthouses require you bring your own. Also, there are court fees so make sure you bring cash. The person you talk to on the phone will be able to tell you all of that location’s rules.`}</li>
      <li parentName="ol">{`If you are changing your name you’ll need to print and fill out an application and send it in with some forms of identification to your Social Security Administration. You could also go in person. Some SSAs allow walk ins and some require an appointment. You won’t get a new SS card that day because they all come from Maryland so you’ll get it in the mail. Once you receive the new SS card you can proceed to change your name on all other accounts and cards. `}</li>
    </ol>
    <p>{`As a wedding celebration, we are going on a vacation with both of our immediate families. We decided that was the best way to unify our families and spend quality time together. Afterall, they are the only people that will consistently be in your lives throughout the years. We have a big vacation house booked in April. Sean and I already paid half up front and we have until March to have it paid in full. While there, we are going to get dressed up for a family photoshoot. I can’t wait to have our families together! `}</p>
    <p>{`I want to finish this up by saying that if you are not married yet or if you are in the planning process, I hope our wedding planning is sign for you. We want you to know that you can do things how you want, when you want and with whom you want. You don’t have to cave if there is family pressure to do things a certain way. ( My acquaintance’s fiance’s family is basically making them spend easily over $30,000 on the wedding and every time I see her she talks about how much she doesn’t want to ) You don’t have to feel guilty about the way you want to get married. ( I have been mad at a friend about her wedding in the past and I hate myself for it. I made such a big deal out of something that should not have been! I regret that, a lot. My friend already had enough to think about and I added extra pressure. Unnecessary. Also, I have felt this way for a long time, not just because I got engaged) You don’t have to feel guilty if you are not including certain people. You CAN invite someone without inviting others. You don’t have to send cards to everyone, only send cards to people who you genuinely believe will cherish getting a card from you. Back up and rethink your wedding plans if you want to! Your marriage is all about you and your spouse, the way it should be. Sean and I didn’t ask our families for money or to pay for a certain things. We didn’t put ourselves in debt or cause unnecessary stress to ourselves or our family members… no one had to take off work, book flights or anything like that. We got married exactly how we wanted to and we are celebrating exactly how we want to. We hope you do the same! `}</p>
    <p>{`P.S. No shame to those who want/ have/ had big weddings! `}</p>
    <p>{`Questions:
How long was it from the time you got engaged until the time you started actively wedding planning? And how was your process? Did you have pressures? `}</p>
    <p>{`What do you wish you had done differently with your wedding?`}</p>
    <p>{`How do you feel about the dress expenses? What expenses were and were NOT worth it? `}</p>
    <p>{`How often do both sides of the families get together?`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

