import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, addDoc, collection, getDocs } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsBSm7lRZrnv69fHHi9Uyp7MbV2ONLSEc",
  authDomain: "morgan-ann.firebaseapp.com",
  databaseURL: "https://morgan-ann-default-rtdb.firebaseio.com",
  projectId: "morgan-ann",
  storageBucket: "morgan-ann.appspot.com",
  messagingSenderId: "362320840953",
  appId: "1:362320840953:web:27acd275a9687023dc7a28",
  measurementId: "G-G53T3J44E1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);


const commentCollection = () => {
  return collection(db, "comments");
};

export const addComment = async (comment) => (
  await addDoc(commentCollection(), comment)
);

export const getComments = async (postId) => {
  const snapshot = await getDocs(commentCollection());
  return snapshot.docs
    .map(doc => ({ id: doc.id, ...doc.data() }))
    .filter(doc => doc.postId === postId)
    .sort(sortByCreated);
};

const sortByCreated = (a, b) => {
  if (a.createdOn > b.createdOn) return -1;
  else if (a.createdOn < b.createdOn) return 1;
  else return 0;
};
