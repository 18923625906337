import React from 'react';
import cls from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const SubLink = ({ className, children, ...props }) => {
    return ( 
        <Link className={cls(styles.sublinks, className)} {...props}>{children}</Link>
    );
}

export default SubLink; 