import React from 'react';
import { Link } from 'react-router-dom';
import Wrapper from '../../components/Wrapper';
import styles from './styles.module.css';

export default function Home() {
	return (
		<Wrapper>
			<div className={styles.gridLayout}>
				<Link className={styles.lifestyle} to="/lifestyle">
					LIFESTYLE
				</Link>

				<Link className={styles.skinCare} to="/skincare">
					SKINCARE
				</Link>
			</div>
		</Wrapper>
	);
}
