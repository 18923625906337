import { useState, useEffect } from 'react';
import { getComments } from '../firebase';

const initState = { loading: true, data: null, error: null };

const useComments = (postId) => {
  const [ state, setState ] = useState(initState);
  const [ invalid, setInvalid ] = useState(0);

  const invalidate = () => setInvalid(invalid + 1);

  useEffect(() => {
    getComments(postId)
      .then(comments => setState({ loading: false, data: comments, error: null }))
      .catch(e => setState({ loading: false, data: null, error: e }));
  }, [ postId, invalid ]);

  return [ state, invalidate ];
};

export default useComments;
