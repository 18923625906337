import React from 'react';
import Wrapper from '../../../components/Wrapper';
import glymed_plus_pic from '../../../images/glymed-plus-pic.jpg'
import styles from './styles.module.css';

const GlymedPlusSeries = () => {
    return (
        <Wrapper>
            <div className={styles.glymed} >
                <a href="https://glymedplus.com/store/shapesesthetics" target="_blank" rel="noopener noreferrer">
                    <img src={glymed_plus_pic} alt="glymed plus store" />
                </a>
            </div>
        </Wrapper>
    );
};

export default GlymedPlusSeries;