import React from 'react';
import cls from 'classnames';
import Header from '../Header';
import Morgan from '../../components/Morgan';
import styles from './styles.module.css';

const Wrapper = (props) => {
  return (
    <div className={cls(styles.wrapper, props.className)}>
      <Header className={styles.header} />

      <div className={styles.body}> 
        <Morgan className={styles.morgan} /> 
        <div className={styles.content}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
