import React, { useMemo, useState } from 'react';
import { formatDistance } from 'date-fns';
import cls from 'classnames';
import { useComments } from '../../hooks';
import { addComment } from '../../firebase';
import styles from './styles.module.css';

const Comments = (props) => {
  const [ comments, invalidate ] = useComments(props.postId);
  const [ text, setText ] = useState('');
  const [ fullName, setName ] = useState('');

  const saveComment = (event) => {
    event.preventDefault();
    addComment({
      postId: props.postId,
      createdOn: Date.now(),
      fullName,
      text,
    });
    setText('');
    setName('');
    invalidate();
  };

  return (
    <div className={cls(styles.comments, props.className)}>
      <div className={styles.formContainer}>
        <form onSubmit={saveComment}>
          <textarea
            placeholder={props.commentPlaceholder ?? "Add a Comment"}
            value={text}
            onChange={(event) => setText(event.target.value)}
          />

          <input
            type="text"
            placeholder="Display Name"
            value={fullName}
            onChange={(event) => setName(event.target.value)}
          />

          <input type="submit" value="Submit" />
        </form>
      </div>

      <RenderComments comments={comments} />
    </div>
  );
};

const RenderComments = ({ comments, ...props }) => {
  const rows = useMemo(() => {
    if (comments.data == null) return [];

    return comments.data.map((comm) => {
      const avatar = `url(https://ui-avatars.com/api/?background=random&name=${encodeURIComponent(comm.fullName)})`;
      const createdOn = formatDistance(comm.createdOn, new Date(), { addSuffix: true })

      return (
        <div key={comm.id} className={styles.commentRow}>
          <div className={styles.text}>{comm.text}</div>
          <div className={styles.user}>
            <div className={styles.avatar} style={({ backgroundImage: avatar })} />
            <span>
              <div>{comm.fullName}</div>
              <div>{createdOn}</div>
            </span>
          </div>
        </div>
      );
    });
  }, [ comments.data ]);

  if (comments.loading) {
    return (<>Loading...</>);
  }

  if (comments.error != null) {
    return (<>{comments.error.toString()}</>);
  }

  return (
    <div className={cls(styles.commentList, props.className)}>
      {rows}
    </div>
  );
};

export default Comments;
