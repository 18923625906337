import React from 'react';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import InstagramButton from '../InstagramButton';
import SkincareButton from '../SkincareButton';
import styles from './styles.module.css';

const Header = (props) => {
	return (
		<div className={cls(styles.header, props.className)}>
			<Link to="/">HOME</Link>
			<Link to="/lifestyle">LIFESTYLE</Link>
			<Link to="/skincare">SKINCARE</Link>
			<InstagramButton />
			<SkincareButton />
		</div>
	);
};

export default Header;
