
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"6 min read","minutes":5.345,"time":320700,"words":1069}
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "spansh-learning-introduction"
    }}>{`Spansh Learning Introduction`}</h1>
    <p>{`If you have been following my Instagram you know that I have been taking Spanish classes since January 2021. It has been a fun ride so far! It’s hard, it pushes me and I am very excited to share in detail with you more about this journey now and in the future.`}</p>
    <p>{`My first attraction of wanting to learn Spanish came from my husband’s daughter. She is mostly Mexican and was practicing her Spanish on the Duo Lingo app. So, I started too! I thought it would be something fun that we could bond over. She is so consistent on the app and I was too for a little bit, but I guess it became too much of a connivence because I would push it off for another day. So, my scores were going down and eventually I quit doing it. If you are interested in learning a second language for free that is a good start! (It’s not really in my personality to finish things I don’t pay for. So, when I started paying for classes I knew I was all in, for that semester at least.)`}</p>
    <p>{`Then, I found a local school here in the Phoenix area. It’s not through a community college or university, which I love! Classes at CC or university can be quite large and there isn’t a lot of time for one on one attention. My class sizes range from 5–7 students. Classes are twice a week for 90 minutes. My teacher used to do the classes in person but ever since the fall of 2020 (lol) she got out of her building lease and it’s all online now. Everyday my confidence grows, but I am scared to speak Spanish in real life- definitely something I need to work on. `}</p>
    <p>{`Learning Spanish is important to me for a few reasons! It never hurts to know a second language, I think we can all agree on that. But, my reasonings go a little deeper than that. The overall American mentality of foreign languages/ accents really bother me. Canada and Mexico, for the most part, know at least two languages! Knowing a second language and overcoming the obstacles that come with it is something that should be admired. I do, however, understand people’s frustration with accents! I, myself have been in a situation where I had to say over the phone “I need you to transfer me to someone I can understand better.”`}</p>
    <p>{` I wish the American school system did a better job at promoting a second language. However, the school systems might respond positively if it were a priority to parents throughout our country as well. So, I encourage you, if it’s something that you want at the elementary school level for your kids and for the future generation, speak up, start a group, come up with activities. I’ll be with you in Spirit! `}</p>
    <p>{`Which brings me to my next point, it is very important for me to have bilingual children. Even if one day I give up on Spanish and think I have learned all that my brain can handle- My plan is to have a bilingual children. I already know that I will seek out bilingual daycares, babysitters, toys, games, shows and after school programs. `}</p>
    <p>{`Many studies show that it is more beneficial to learn a second language as a child. It makes them better thinkers, better problem solvers, they get a better awareness of their surrounding and more. We all know that children soak the world up like a sponge, so why not give them a leg up? I will provide some links for you to watch. `}</p>
    <p>{`To continue with the children, I just want to say this: You never know who your kids will fall in love with and where they will end up living. I married a person who has a Mexican, Navajo and White mixed daughter. I’ve also moved from Kentucky to Utah and now I’m in Arizona. Life changes all the time, we can all agree on that! Your children might study abroad on day and they will wish they knew the language or start a business and wish they knew a second language to advertise to a different market.`}</p>
    <p>{` Someone in my class is learning Spanish because she has a Mexican boyfriend and wants to learn for their future together ( kids and a closer relationship with his family ) Another guy in my class is planning a tour through Spanish speaking countries for next year, he is taking classes so he can submerge in the culture better. The list of examples go on and they are all important and amazing! `}</p>
    <p>{`Although having bilingual children is my most important reason for taking on this task of language learning, there are other reasons. I am building my resume. I’m not sure if every job pays more or starts off at a higher position for knowing a second language, but then again I’m not really doing it for that reason. I would love to have some Spanish speaking clientele one day! `}</p>
    <p>{`Lastly, I’m looking at this journey as a way of being patriotic! You may think that you live in an area of the country where knowing a second language is not needed, and that may have been the case for the last three+ generations. However, recently our country’s influx of Spanish speakers is… astronomical in my opionion. Therefore, I don’t think it matters what part of the country you live in, our future generations will benefit from knowing more than the English
language and it’s up to this generation to promote it. English is by far becoming the most dominant language, which might turn you off to learning a new one. But, I think there is something special to keeping a language alive. Like I said, my husband’s daughter has a lot of Navajo in her and it would be SO COOL if she knew the Native language! That langague will be lost soon, and that makes me sad for her. `}</p>
    <p>{`I encourage you to find a local school in your area and to shadow a class so you can get the feel of it! I shadowed a class before taking the plunge and I’m so glad that I did! `}</p>
    <p>{`Links for you to watch:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtu.be/MMmOLN5zBLY"
        }}>{`The benefits of a bilingual brain - Mia Nacamulli`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtu.be/Bp2Fvkt-TRM"
        }}>{`Creating bilingual minds - Naja Ferjan Ramirez`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

