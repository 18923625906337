import React from 'react';
import cls from 'classnames';
import instagram_button from '../../images/instagram.png';
import styles from './styles.module.css';

const InstagramButton = (props) => {
	return (
		<div className={cls(styles.instagram, props.className)}>
			<a href="https://www.instagram.com/morganann.io/" target="_blank" rel="noopener noreferrer">
                <img src={instagram_button} alt="instagram" />
            </a>
		</div>
	);
};

export default InstagramButton;