import React from 'react';
import cls from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

const Morgan = (props) => {
  return (
    <div className={cls(styles.morgan, props.className)}>
      <Link to="/">Morgan Ann</Link>
    </div>
  );
}

export default Morgan;
