import React from 'react';
import Wrapper from '../../components/Wrapper';
import SubLink from '../../components/SubLink';
import styles from './styles.module.css';

const Lifestyle = () => {
    return (
        <Wrapper>
            <div className={styles.lifestyle}>{"Lifestyle".toUpperCase()}</div>
            <SubLink to="lifestyle/spanish-series">THE SPANISH SERIES</SubLink>
            <SubLink to="lifestyle/marriage-series"> THE MARRIAGE SERIES</SubLink>
        </Wrapper>
    );
};

export default Lifestyle;
export { default as MarriageSeries } from './MarriageSeries';
export { default as SpanishSeries } from './SpanishSeries';