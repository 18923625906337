import { createPhoto } from './createPhoto';

const photos = [
  createPhoto('wedding_pics/wedding-pic91.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic94.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic96.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic98.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic106.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic107.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic115.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic117.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic110.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic111.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic118.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic119.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic120.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic121.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic122.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic123.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic124.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic129.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic130.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic131.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic132.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic133.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic136.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic142.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic143.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic144.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic145.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic146.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic149.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic151.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic152.jpg', 2, 3),
];

export default photos;
