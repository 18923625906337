
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.105,"time":6300,"words":21}
/* @jsxRuntime classic */
/* @jsx mdx */
import WeddingGallery from 'components/WeddingGallery';
import PhotoCred from 'components/PhotoCred';
import photos from 'components/WeddingGallery/photos';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "wedding-photography-one"
    }}>{`WEDDING PHOTOGRAPHY ONE`}</h1>
    <WeddingGallery photos={photos} mdxType="WeddingGallery" />
    <PhotoCred mdxType="PhotoCred" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

