import { createPhoto } from './createPhoto';

const photos = [
  createPhoto('wedding_pics/wedding-pic9.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic10.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic78.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic14.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic15.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic21.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic11.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic16.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic17.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic35.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic22.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic18.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic19.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic20.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic25.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic26.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic27.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic28.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic29.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic30.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic31.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic34.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic100.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic105.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic103.jpg', 3, 2),
];

export default photos;
