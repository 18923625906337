import { createPhoto } from './createPhoto';

const photos = [
  createPhoto('wedding_pics/wedding-pic75.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic74.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic79.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic80.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic83.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic84.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic87.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic89.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic90.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic85.jpg', 3, 2),
  createPhoto('wedding_pics/wedding-pic1.jpg', 2, 3),
  createPhoto('wedding_pics/wedding-pic4.jpg', 2, 3),
];

export default photos;
