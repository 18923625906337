import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import styles from './styles.module.css';
import cls from 'classnames';

const ImageSlider = ({ className, children, ...props }) => {
    return (
		<div className={cls(styles.imageslider, className)}>
            <AliceCarousel {...props}>{children}</AliceCarousel>
		</div>
	);
};

export default ImageSlider;