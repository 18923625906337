
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"6 min read","minutes":5.135,"time":308100,"words":1027}
/* @jsxRuntime classic */
/* @jsx mdx */
import ImageSlider from '../../../components/ImageSlider';
import proposalpost_pic1 from '../../../images/Proposalpost_pics/proposalpost_pic1.jpg';
import proposalpost_pic3 from '../../../images/Proposalpost_pics/proposalpost_pic3.jpg';
import proposalpost_pic4 from '../../../images/Proposalpost_pics/proposalpost_pic4.jpg';
import proposalpost_pic5 from '../../../images/Proposalpost_pics/proposalpost_pic5.jpg';
import proposalpost_pic6 from '../../../images/Proposalpost_pics/proposalpost_pic6.jpg';
import proposalpost_pic7 from '../../../images/Proposalpost_pics/proposalpost_pic7.jpg';
import proposalpost_pic8 from '../../../images/Proposalpost_pics/proposalpost_pic8.jpg';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "the-proposal"
    }}>{`The Proposal`}</h1>
    <p>{`Morgan’s POV: `}</p>
    <p>{`I had no clue that Sean was going to propose on the Mexico vacation! However, I did talk to three friends about it prior to going on vacation. My thoughts were “We live together. We pay bills together. And it’s been 5 years… How could he NOT propose on a two week vacation in Mexico.” I asked my three friends and had them ask their spouses if they thought I had the right to be mad if he didn’t propose. All three of my girlfriends said yes and all three of their husband’s said no, haha! Men!!!! Their spouses almost said the exact same thing. They basically said I could be disappointed but not angry with Sean… What do they know anyway!`}</p>
    <p>{`Probably six months prior to this vacation we had a few casual chats about getting married ‘ one day ‘  but it was never an in-depth conversation. I had mentioned to him that I wanted a video of him proposing to me. And I had shown him a few ring styles that I like. But, nothing ever came from it. During this time Sean was working from home and I did not have a job. Therefore, we never went anywhere without the other and if one of us got a package the other one knew about it… So there was no evidence of Sean getting a ring for me.`}</p>
    <p>{`The two weeks leading up to the vacation I was already mad about him not proposing during the vacation, haha. I couldn’t control it! I was being short with Sean, being rude and picking fights for no reason. It was obvious there was something wrong with me. He asked me a few times but I never admitted to it. Here we were about to go on an amazing trip - Sean took off two weeks of work and at the time I was not working - and here I am being ungrateful…  `}</p>
    <p>{`Once we got in the hustle of packing and traveling I didn’t have time to be angry so I honestly forgot about it. And the same thing whenever we arrived in Mexico; I was just happy to be on island time. We were too busy dancing and eating and exploring for me to be angry about something that hasn’t happened yet. `}</p>
    <p>{`Towards the end of the trip I did mention my thoughts to Sean’s daughter, Maleya. We were having girl time in the ocean when I brought it up. I just mentioned that I thought he would have done it by now because we are on such a luxurious vacation and it’s been five years. At this point in time we had three days of vacation left. She responded like a teenager who didn’t want her dad getting married and said “ I just didn’t think that was your two’s ‘thing’ and kind of rolled her eye so the conversation stopped there, haha … Little did I know she was excited to be in on this big secret! `}</p>
    <p>{`Sean’s POV: `}</p>
    <p>{`I had no clue I was going to propose to Morgan on our Mexico vacation. I had been looking for a good opportunity to propose for a while and a two week vacation in Mexico seemed like a good idea. I probably should have planned it out beforehand, but that’s not my style so I played it by ear. This was supposed to be a vacation for my daughter, Maleya, and I didn’t want to make the trip about me and Morgan. Therefore, I knew I’d have to wait until the last minute to propose. Also, I wanted to avoid bringing a ring to Mexico so I would have to find a ring to propose with. `}</p>
    <p>{`For the first week I didn’t give it much thought since I knew I had to wait towards the end of the vacation, but after the first week I started sneaking around to find a good ring shop. The ring was actually easy to find because the local stores sold similar items. So, I kept it simple with a silver band. After finding the ring it was time to bring in my accomplice. `}</p>
    <p>{`(Side note: We were also celebrating Morgan’s 29th birthday on this trip. It was going to be just the two of us for dinner.) While Morgan was showering for her birthday dinner I asked Maleya if she had any objections about me proposing. Maleya started dancing and giggling like something was medically wrong with her and I immediately thought ‘Okay, good she approves!’ But, I double checked to be on the safe side; I was in the clear. I didn’t want to make Morgan’s birthday about the proposal so I decided to wait for another night.  Plus, I wanted Maleya to film it and needed to call Morgan’s father for his blessing. Her father of course said yes in a more grateful manner than I probably deserved.`}</p>
    <p>{`I knew the last piece of the puzzle was finding a night when Morgan would be dressed up enough to be happy with the video that would ultimately be a one take moment. I was able to convince everyone in our group that we needed to try this nice beachfront restaurant before our vacation ended. `}</p>
    <p>{`At the restaurant around sunset Maleya asked for some family photos by the shore. So, we walked down to take photos of the three of us and then Maleya said “Okay, I’ll take some of just you two” which I knew Morgan would want anyway so the plan was getting executed perfectly! Maleya did such a good job at all of this!`}</p>
    <p>{`It was there on the beachfront, with the entire restaurant watching (Maleya filming) that I got on one knee and asked Morgan to be my wife.`}</p>
    <ImageSlider mdxType="ImageSlider">
    <img src={proposalpost_pic1} />
    <img src={proposalpost_pic3} />
    <img src={proposalpost_pic4} />
    </ImageSlider>
    <ImageSlider mdxType="ImageSlider">
    <img src={proposalpost_pic5} />
    <img src={proposalpost_pic6} />
    </ImageSlider>
    <ImageSlider mdxType="ImageSlider">
    <img src={proposalpost_pic7} />
    <img src={proposalpost_pic8} />
    </ImageSlider>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {}

