import React from 'react';
import Wrapper from '../../../components/Wrapper';
import SubLink from '../../../components/SubLink';
import styles from './styles.module.css';

const MarriageSeries = () => {
    return (
        <Wrapper>
            <SubLink className={styles.sublinks} to="/lifestyle/marriage-series/post-one">THE PROPOSAL</SubLink>
            <SubLink className={styles.sublinks} to="/lifestyle/marriage-series/post-two">WEDDING PLANNING</SubLink>
            <SubLink className={styles.sublinks} to="/lifestyle/marriage-series/post-three">WEDDING PHOTOGRAPHY ONE</SubLink>
            <SubLink className={styles.sublinks} to="/lifestyle/marriage-series/post-four">WEDDING PHOTOGRAPHY TWO</SubLink>
            <SubLink className={styles.sublinks} to="/lifestyle/marriage-series/post-five">WEDDING PHOTOGRAPHY THREE</SubLink>
            <SubLink className={styles.sublinks} to="/lifestyle/marriage-series/post-six">WEDDING PHOTOGRAPHY FOUR</SubLink>
        </Wrapper>
    );
};

export default MarriageSeries;
