import React from 'react';
import { MDXProvider } from '@mdx-js/react'
import Wrapper from '../Wrapper';
import Comments from '../Comments';
import styles from './styles.module.css';

const PostWrapper = (props) => {
    return (
        <Wrapper>
            <div className={styles.postWrapper}>
                <MDXProvider>
                    {props.children}
                </MDXProvider>
            </div>
            <Comments postId={props.postId} />
        </Wrapper>
    );
};

export default PostWrapper;
